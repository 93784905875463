import React, { Component } from 'react';
import configData from '../../../includes/config.json';
import axios from 'axios';
import './styles.scss';

class AdminHeader extends Component {
	constructor() {
		super();
		this.LoggoutBtn_handler = this.LoggoutBtn_handler.bind(this);
	}

	componentDidMount() {
		// this.isAuthorized();
	}

	async isAuthorized() {
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		const response = await axios({
			method: 'POST',
			url: configData.server_URI + '/is-authorized',
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (response.data === 'unauthorized') {
			localStorage.removeItem('loggedId');
			window.location = '/';
		}
	}

	LoggoutBtn_handler(e) {
		localStorage.removeItem('loggedId');
		localStorage.removeItem("viewAsOwner")
		localStorage.removeItem("perm")
		window.location = '/admin';
	}

	render() {
		var loggoutBtn = '';
		const accountID = localStorage.getItem('loggedId');
		if (accountID) {
			loggoutBtn = (
				<button className="AdminHeaderLoggoutBtn" onClick={this.LoggoutBtn_handler}>
					تسجيل خروج
				</button>
			);
		}
		return (
			<div className="AdminHeader">
				<span className="AdminHeaderTitle">لوحة الادارة</span>
				{loggoutBtn}
			</div>
		);
	}
}

export default AdminHeader;
